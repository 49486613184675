import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import {
  Layout,
  Grid, notification, Button,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import {useThemeSwitcher} from "react-css-theme-switcher";
import {AUTH_TOKEN} from "../../redux/constants/Auth";
import {axiosClient} from "../../api/api";

const {Content} = Layout;
const {useBreakpoint} = Grid;

export const AppLayout = ({navCollapsed, navType, location, direction}) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const checkTAPCode = () => {
    const code = localStorage.getItem('AccessPermissionToken');

    if (!code) {
      window.location.href = '/auth/login/';
    }
  }

  const checkAdminAuth = () => {
    axiosClient.get('/auth/admin/check').then((res) => {
      const {remainTime} = res.data;

      if (remainTime < 15 * 60) {
        const key = `token-alert`;
        const btn = (
          <Button type="primary" size="small" onClick={() => {
            axiosClient.get('/auth/admin/refresh').then((res) => {
              const token = res.data.token;
              localStorage.setItem('AccessPermissionToken', token);
              notification.close(key);

              notification.open({
                message: '보안 경고',
                description:
                  '토큰이 갱신되었습니다.',
                duration: 3,
                key: 'token-refresh-success',
                onClose: () => {

                },
              });
            }).catch((err) => {
              alert('토큰 갱신에 실패했습니다. 작업중인 내용을 보호하려면 즉시 재로그인하세요.');
            });
          }}>
            토큰 갱신
          </Button>
        );

        notification.open({
          message: '보안 경고',
          description:
            '어드민 인증 토큰이 곧 만료됩니다. 어드민이 종료될 수 있습니다. 작업중인 내용을 보호하려면 아래 버튼을 눌러 토큰을 재발급해주세요.',
          duration: 0,
          btn,
          key,
          onClose: () => {

          },
        });
      }
    }).catch((err) => {
      console.log(err);

      // window.location.href = '/auth/login/';
      notification.open({
        message: '보안 경고',
        description:
          '네트워크 문제로 토큰 유효성 인증에 실패하였습니다. 곧 어드민이 종료될 수 있으니 작업중인 내용을 백업하세요.',
        duration: 0,
        key: 'network-alert',
        onClose: () => {

        },
      });


      if (err?.response?.status === 401) {
        notification.open({
          message: '보안 경고',
          description:
            '알 수 없는 문제로 토큰이 무효화 되었습니다. 30초 뒤 어드민이 종료됩니다.',
          duration: 0,
          key: 'network-alert-401-1',
          onClose: () => {

          },
        });

        setTimeout(() => {
          notification.open({
            message: '보안 경고',
            description:
              '알 수 없는 문제로 토큰이 무효화 되었습니다. 20초 뒤 어드민이 종료됩니다.',
            duration: 0,
            key: 'network-alert-401-2',
            onClose: () => {

            },
          });
        }, 10000);

        setTimeout(() => {
          notification.open({
            message: '보안 경고',
            description:
              '알 수 없는 문제로 토큰이 무효화 되었습니다. 10초 뒤 어드민이 종료됩니다.',
            duration: 0,
            key: 'network-alert-401-3',
            onClose: () => {

            },
          });
        }, 20000);

        setTimeout(() => {
          localStorage.removeItem('AccessPermissionToken');
        }, 30000);
      }
    })
  }

  useEffect(() => {
    checkTAPCode();
    checkAdminAuth();

    const check1 = setInterval(() => {
      checkTAPCode();
    }, 1000);

    const check2 = setInterval(() => {
      checkAdminAuth();
    }, 30000);

    return () => {
      clearInterval(check1);
      clearInterval(check2);
    }
  }, []);


  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const {status} = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page"/>;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }
    if (direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }


  return (
    <Layout>
      {/*<HeaderNav isMobile={isMobile}/>*/}
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            {/*<div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>*/}
            {/*<PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />*/}
            <Content>
              <AppViews/>
            </Content>
          </div>
          <Footer/>
        </Layout>
      </Layout>
      {/*{isMobile && <MobileNav />}*/}
    </Layout>
  )
}

const mapStateToProps = ({theme}) => {
  const {navCollapsed, navType, locale} = theme;
  return {navCollapsed, navType, locale}
};

export default connect(mapStateToProps)(React.memo(AppLayout));