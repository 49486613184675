import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH, REPORT_PREFIX_PATH} from 'configs/AppConfig'

export const ReportViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${REPORT_PREFIX_PATH}/feedback`} component={lazy(() => import(`./feedback`))}/>
        <Route path={`${REPORT_PREFIX_PATH}`} component={lazy(() => import(`./report`))}/>
        <Redirect from={`${REPORT_PREFIX_PATH}`} to={`${REPORT_PREFIX_PATH}/dev`}/>
      </Switch>
    </Suspense>
  )
}

export default React.memo(ReportViews);
