import React from 'react'
import {ReportViews} from "../../views/report-views";

export const ReportLayout = () => {
  return (
    <div className="report-container bg-white" style={{display:'flex', justifyContent:'center',}}>
      <ReportViews/>
    </div>
  )
};


export default ReportLayout;
